// Footer
#footer {
	padding: 25px 0;
	background-color: @brand-primary;
	color: #fff;
	font-family: @ff-roboto;
	font-size: 0.94rem;
	line-height: normal;
	p {
		margin: 0;
	}
	a {
		color: #fff;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}