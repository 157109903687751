// Produit
body.node-type-produits {
	#description {
		padding-top: 55px;
		padding-bottom: 155px;
		background-color: #fff;
		.title {
			margin-top: 0;
			margin-bottom: 45px;
		}
		img {
			margin-top: 80px;
		}
	}

	#brand {
		margin-top: -85px;
		.brands {
			float: right;
			padding: 0 60px;
			@media (max-width: @screen-sm-max) {
				padding: 0;
			}
			@media (max-width: @screen-xs-max) {
				float: none;
			}
		}
		.item--brand {
			float: left;
			@media (max-width: @screen-xs-max) {
				float: none;
			}
			& + .item--brand {
				margin-left: 20px;
				@media (max-width: @screen-xs-max) {
					margin-top: 15px;
					margin-left: 0;
				}
			}
		}
	}

	#medias {
		padding-top: 55px;
		padding-bottom: 50px;
		h2 {
			margin-top: 0;
			margin-bottom: 30px;
			font-size: 1.88rem;
		}

		.videos {
			padding-right: 40px;
			@media (max-width: @screen-sm-max) {
				padding-right: 15px;
				padding-bottom: 40px;
			}
			.owl-dots {
				margin-top: 40px;
				font-size: 0;
				text-align: center;
				.owl-dot {
					display: inline-block;
					vertical-align: center;
					height: 11px;
					width: 11px;
					background-color: #cfcfcf;
					border: 2px solid #cfcfcf;
					border-radius: 50%;
					.transition(all .2s);
					&.active, &:hover {
						background-color: transparent;
						border-color: #000;
					}
					& + .owl-dot {
						margin-left: 18px;
					}
				}
			}
		}

		.pdf {
			h3 {
				margin: 0;
				color: #858585;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.88em;
			}
			> ul {
				@media(min-width: @screen-sm-min) {
					-webkit-column-count: 2;
					-moz-column-count: 2;
					column-count: 2;
					> li {
						-webkit-column-break-inside: avoid;
						page-break-inside: avoid;
						break-inside: avoid;
					}
				}
				> li {
					padding-bottom: 30px;
				}
			}
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					a {
						text-decoration: underline;
						.fa {
							margin-right: 8px;
						}
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	#content {
		background-color: #fff;
	}
}