// Header
#header {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    .transition(all .2s);
    .top-header {
        position: relative;
        z-index: 15;
        padding: 15px 25px;
        background-color: @brand-darkblue;
        color: #fff;
        font-size: 0;
        text-align: right;
        .transition(all .2s);
        .topNav, .toggle-search, .language, .trigger-menu {
            display: inline-block;
            vertical-align: middle;
            text-align: left;
        }
    }
    .main-header {
        padding: 12px 0;
        .transition(all .2s);
        @media (max-width: @screen-sm-max) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }


    .topNav {
        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            @media (max-width: @screen-xs-max) {
                margin-top: 60px;
                text-align: center;
            }
            > li {
                float: left;
                @media (max-width: @screen-xs-max) {
                    float: none;
                }
                > a, > .nolink {
                    display: block;
                    padding: 0;
                    background-color: transparent;
                    color: #fff;
                    font-family: @ff-open;
                    font-size: 0.75rem;
                    font-weight: 600;
                    letter-spacing: .5px;
                    line-height: 2.1em;
                    text-decoration: none;
                    text-transform: uppercase;
                    .transition(all .2s);
                    @media (max-width: @screen-xs-max) {
                        padding: 10px 15px;
                    }
                }
                &.active, &.active-trail, &:hover {
                    > a, > .nolink {
                        color: @brand-lightblue;
                    }
                }
            }
        }
    }
    .toggle-search {
        margin-left: 30px;
        color: #fff;
        text-decoration: none;
        .fa {
            display: block;
            font-size: 0.75rem;
            line-height: 2.1em;
        }
        .transition(all .2s);
        &:hover {
            color: @brand-lightblue;
        }
    }
    .language {
        position: relative;
        margin-left: 20px;
        > span, ul li a, ul li span {
            display: block;
            color: #fff;
            font-family: @ff-open;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 2.1em;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
            .transition(all .2s);
            .fa {
                margin-left: 5px;
            }
            &:hover {
                color: @brand-lightblue;
            }
        }
        ul {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #fff;
            li {
                a, span {
                    padding: 5px 10px;
                    color: @brand-primary;
                    &:hover {
                        background-color: @brand-darkblue;
                        color: #fff;
                    }
                }
                span {
                    background-color: transparent !important;
                    color: #ccc !important;
                    cursor: default;
                }
            }
            &.expand {
                display: block;
            }
        }
    }

    #searchBox {
        position: absolute;
        z-index: 10;
        top: -100px;
        left: 0;
        padding: 20px 15px;
        width: 100%;
        background-color: @brand-primary;
        .transition(all .2s);
        .block-search {
            margin-left: auto;
            margin-right: auto;
            max-width: 960px;
        }
        &.expand {
            top: 55px;
        }
    }

    .logo {
        float: left;
        padding: 12px;
        .transition(all .2s);
        img {
            width: 168px;
            -webkit-filter: brightness(0) invert(1);
            -moz-filter: brightness(0) invert(1);
            -ms-filter: brightness(0) invert(1);
            -o-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
            .transition(all .2s);
        }
    }

    #nav {
        float: right;
        margin: 25px 0;
        font-family: @ff-open;
        .transition(all .2s);
        > ul {
            float: none;
            margin: 0;
            padding: 0;
            list-style: none;
            > li {
                float: left;
                @media (max-width: @screen-sm-max) {
                    float: none;
                }
                > a, > .nolink {
                    display: block;
                    padding: 20px 15px;
                    background-color: transparent;
                    color: #fff;
                    font-size: 0.81rem;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration: none;
                    text-transform: uppercase;
                    .transition(all .2s);
                    > .caret {
                        display: none;
                    }
                }
                &.active, &.active-trail, &:hover {
                    > a, > .nolink {
                        color: @brand-lightblue;
                    }
                }
                &:hover {
                    > .dropdown-menu {
                        display: block;
                    }
                }
                > .dropdown-menu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    float: none;
                    margin: 0;
                    padding: 0;
                    min-width: 150px;
                    list-style: none;
                    background-color: #fff;
                    border: 0;
                    border-radius: 0;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    @media (max-width: @screen-sm-max) {
                        position: relative;
                        top: auto;
                        left: auto;
                        text-align: center;
                    }
                    > li {
                        > a, > .nolink {
                            display: block;
                            padding: 10px 10px;
                            background-color: transparent;
                            color: @brand-darkblue;
                            font-size: 0.81rem;
                            font-weight: 700;
                            line-height: normal;
                            text-decoration: none;
                            .transition(all .2s);
                            > .caret {
                                display: none;
                            }
                        }
                        & + li {
                            border-top: 1px solid @brand-darkblue;
                        }
                        &.active, &.active-trail, &:hover {
                            > a, > .nolink {
                                background-color: @brand-darkblue;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

#header.scrolled, body.not-front #header {
    .main-header {
        @media (min-width: @screen-md-min) {
            background-color: #f3f3f3;
        }
    }
    .logo {
        img {
            -webkit-filter: none;
            -moz-filter: none;
            -ms-filter: none;
            -o-filter: none;
            filter: none;
        }
        @media (max-width: @screen-sm-max) {
            position: absolute;
            z-index: 15;
            top: 0;
            padding: 5px;
            img {
                width: 85px !important;
                -webkit-filter: brightness(0) invert(1);
                -moz-filter: brightness(0) invert(1);
                -ms-filter: brightness(0) invert(1);
                -o-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
            }
        }
    }
    #nav {
        > ul {
            > li {
                > a, > .nolink {
                    color: @brand-darkblue;
                    @media (max-width: @screen-sm-max) {
                        color: #fff;
                    }
                }
                &.active, &.active-trail, &:hover {
                    > a, > .nolink {
                        color: @brand-lightblue;
                    }
                }
            }
        }
    }
}

#header.scrolled {
    .top-header {
        @media(min-width: @screen-md-min) {
            margin-top: -55px;
        }
    }
    .main-header {
        padding-top: 0;
        padding-bottom: 0;
    }
    .logo {
        img {
            width: 96px;
        }
    }
    #nav {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}


.trigger-menu {
    position: relative;
    z-index: 1;
    margin-left: 25px;
    svg {
    	display: block;
        fill: #fff !important;
    	rect {
    		-webkit-transform-origin: left center;
    		-moz-transform-origin: left center;
    		-ms-transform-origin: left center;
    		-o-transform-origin: left center;
    		transform-origin: left center;
    		-webkit-transform: rotate(0);
    		-moz-transform: rotate(0);
    		-ms-transform: rotate(0);
    		-o-transform: rotate(0);
    		transform: rotate(0);
    		.transition(all .2s);
    	}
    }
    &:hover {
        svg {
            rect {
                fill: @brand-lightblue !important;
            }
        }
    }
    &.open {
    	svg {
    		rect {
                fill: #fff !important;
    			&:nth-child(1) {
    				-webkit-transform: rotate(45deg);
    				-moz-transform: rotate(45deg);
    				-ms-transform: rotate(45deg);
    				-o-transform: rotate(45deg);
    				transform: rotate(45deg);
    				x: 3px;
    			}
    			&:nth-child(2) {
    				width: 0%;
    				opacity: 0;
    			}
    			&:nth-child(3) {
    				-webkit-transform: rotate-(45deg);
    				-moz-transform: rotate(-45deg);
    				-ms-transform: rotate(-45deg);
    				-o-transform: rotate(-45deg);
    				transform: rotate(-45deg);
    				x: 3px;
    			}
    		}
    	}
    }
}

@media (max-width: @screen-sm-max) {
    nav#nav {
        position: fixed;
        z-index: 5;
        margin: 0 !important;
        padding: 60px 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: fade(@brand-primary,90%);
        overflow-x: auto;
        overflow-y: scroll;
        .transition(all .35s);
    }
}

body.open-menu {
    @media (max-width: @screen-sm-max) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}

#banner {
    position: relative;
    height: 325px;
    background-color: #000000;
    .banner-title {
        .v-align;
        z-index: 5;
        width: 100%;
        .container {
            position: relative;
        }
        h1 {
            margin: 0;
            padding-right: 300px;
            color: #fff;
            @media (max-width: @screen-sm-max) {
                margin-bottom: 30px;
                padding-right: 0;
            }
            @media (max-width: @screen-xs-max) {
                font-size: 2.25rem;
            }
        }
        .btn {
            position: absolute;
            top: 0;
            right: 15px;
            @media (max-width: @screen-sm-max) {
                position: relative;
                top: auto;
                right: auto;
            }
        }
    }
    .bg {
        opacity: .5;
    }
    &.banner--product {
        background-color: @brand-primary;
        .bg {
            opacity: 1;
            mix-blend-mode: screen;
        }
    }
    &.no-img {
        background-color: @brand-primary;
        .banner-title {
            h1 {
                color: #fff;
            }
        }
    }
}