// Home
#leaderboard {
	.cycle-slideshow {
		height: 635px;
		width: 100%;
		@media (max-width: @screen-xs-max) {
			height: 540px;
		}
		.slide {
			position: relative;
			height: 635px;
			width: 100%;
			background-color: @brand-primary;
			@media (max-width: @screen-xs-max) {
			height: 540px;
			}
			&:before {
				content:'';
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: @brand-primary;
				// mix-blend-mode: color;
				opacity: .8;
			}
			.content {
				position: absolute;
				z-index: 5;
				bottom: 140px;
				left: 0;
				width: 100%;
				@media (max-width: @screen-xs-max) {
					bottom: 90px;
				}
			}
			.slogan {
				margin-bottom: 10px;
				width: 185px;
			}
			h2 {
				margin: 0;
				color: #fff;
				font-size: 3rem;
				font-weight: 100;
				max-width: 540px;
				@media (max-width: @screen-xs-max) {
					font-size: 1.88rem;
				}
				span {
					color: @brand-lightblue;
				}
			}
		}
		.pager-wrapper {
			position: absolute;
			z-index: 120;
			bottom: 75px;
			left: 0;
			width: 100%;
			@media (max-width: @screen-xs-max) {
				bottom: 20px;
			}
		}
		#custom-pager {
			.clearfix;
			span {
				display: block;
				float: left;
				height: 10px;
				width: 10px;
				background-color: transparent;
				border: 2px solid #fff;
				border-radius: 50%;
				cursor: pointer;
				text-indent: -9999px;
				.transition(all .2s);
				& + span {
					margin-left: 30px;
				}
				&:hover, &.cycle-pager-active {
					background-color: #fff;
				}
			}
		}
	}
}

#blocs {
	.clearfix;
	.bloc-wrapper {
		float: left;
		padding: 0 1px;
		width: 20%;
		background-color: #f3f3f3;
		@media(max-width: @screen-xs-max) {
			padding: 1px 0;
			width: 100%;
		}
		/* &:last-child {
			.bloc {
				h3 {
					color: #fff !important;
				}
			}
		} */
	}
	.bloc {
		display: block;
		position: relative;
		height: 220px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		text-decoration: none;
		.transition(box-shadow .2s);
		h3 {
			margin: 0;
			padding: 35px 30px;
			font-size: 1.13rem;
			font-weight: 700;
			@media (max-width: @screen-md-max) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		&:hover {
			z-index: 1;
			box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.2);
		}
	}
}

#intro {
	position: relative;
	margin-top: 100px;
	overflow: hidden;
	@media(max-width: @screen-xs-max) {
		margin-top: 0;
		padding-bottom: 60px;
	}
	.content {
		position: relative;
		z-index: 1;
		.inner-wrapper {
			padding-top: 135px;
			padding-bottom: 90px;
			@media(max-width: @screen-xs-max) {
				padding-bottom: 0;
			}
			.wrapper {
				position: relative;
				padding: 1px 75px 0;
				max-width: 570px;
				background-color: #fff;
				@media(max-width: @screen-sm-max) {
					padding-left: 45px;
					padding-right: 45px;
				}
				@media(max-width: @screen-xs-max) {
					padding-left: 15px;
					padding-right: 15px;
				}
				&:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					right: 100%;
					width: 9999px;
					background-color: #fff;
				}
				@media(max-width: @screen-xs-max) {
					&:after {
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						left: 100%;
						width: 9999px;
						background-color: #fff;
					}
				}
				.text {
					margin-top: -135px;
					padding-top: 60px;
				}
			}
		}
		h1 {
			margin: 0 0 95px;
			font-size: 2.5rem;
			font-weight: 300;
			max-width: 350px;
			@media (max-width: @screen-sm-max) {
				max-width: 330px;
			}
			@media(max-width: @screen-xs-max) {
				margin-bottom: 45px;
				max-width: none;
			}
		}
		p {
			margin: 0 0 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.btn {
			margin-top: 30px;
			padding-left: 35px;
			padding-right: 35px;
			border-radius: 0;
			&:after {
				content: '>';
				margin-left: 35px;
			}
		}
	}
	.photo {
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		@media(max-width: @screen-xs-max) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			padding-bottom: 56.25%;
		}
	}
}

#marques {
	padding-top: 70px;
	padding-bottom: 80px;
	background-color: #fff;
	h2 {
		margin: 0 0 50px;
		font-size: 2.5rem;
		font-weight: 300;
		text-align: center;
	}
	.owl-carousel {
		display: block;
		text-align: center;
		> .item {
			display: inline-block;
			vertical-align: middle;
			width: 230px;
		}
	}
	.item {
		position: relative;
		height: 90px;
		img {
			width: auto;
		}
		> * {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%,-50%);
			-moz-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
			-o-transform: translate(-50%,-50%);
			transform: translate(-50%,-50%);
		}
	}
	&.pi {
		padding-top: 55px;
		h2 {
			margin: 0 0 70px;
			font-size: 2.25rem;
			font-weight: 500;
		}
	}
}

#actualites {
	padding-top: 80px;
	padding-bottom: 150px;
	@media(max-width: @screen-xs-max) {
		padding-bottom: 75px;
	}
	h2 {
		margin: 0 0 15px;
		font-size: 2.5rem;
		font-weight: 300;
	}
}