body.not-front {
	padding-top: 190px;
	@media (max-width: @screen-sm-max) {
		padding-top: 55px;
	}
}

body.admin-menu.adminimal-menu {
	#header {
		top: 29px;
		#searchBox {
			top: -158px;
		}
	}
}

.container {
	&.md-width {
		max-width: 960px;
	}
}

.no-gap {
	padding-left: 0;
	padding-right: 0;
}

.v-align {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0,-50%);
	-moz-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	-o-transform: translate(0,-50%);
	transform: translate(0,-50%);
}

.bg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

// Animate
.animated {
	.animated;
}
.os-animation {
	opacity: 0;
	&.animated {
		opacity: 1;
	}
	@media (max-width: @screen-xs-max) {
		-webkit-animation-delay: 0s !important;
		-moz-animation-delay: 0s !important;
		-ms-animation-delay: 0s !important;
		-o-animation-delay: 0s !important;
		animation-delay: 0s !important;
	}
}

// Content Body
.content-body {
	position: relative;
	max-width: 1170px;
	margin: 0 auto;
	padding: 60px 15px 90px;

	// All
	& > * {
		max-width: 930px;
		margin-left: auto;
		margin-right: auto;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
	
	// Aside
	& > aside {
		max-width: 1140px;
	}

	// Colonnes
	& > .row {
		margin-bottom: 30px;
		
		> div[class*="col-"]:first-child {
			padding-left: 0;
			@media (max-width: @screen-sm-max) {
				padding-right: 0;
			}
		}

		> div[class*="col-"]:last-child {
			padding-right: 0;
			@media (max-width: @screen-sm-max) {
				padding-left: 0;
			}
		}

		.row {
			margin-left: 0;
			margin-right: 0;
			@media (min-width: @screen-md-min) {
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
			}
		}

		.col-md-3, .col-md-6 .col-md-6 {
			padding-left: 20px;
			padding-right: 20px;
			border-left: 1px solid #d3d3d3;
			line-height: 1.5em;
			@media (max-width: @screen-sm-max) {
				padding: 20px 0;
				border-top: 1px solid #d3d3d3;
				border-left: 0;
			}
		}

		h2 {
			margin-bottom: 0;
		}
	}
	
	p[style*="text-align: center;"] {
		img {
			display: inline-block;
		}
	}
}

// Types
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	line-height: normal;
}
h1,.h1 {
	font-weight: 100;
}
h2,.h2 {
	margin-top: 50px;
}
p {
	margin-bottom: 30px;
	&:last-child {
		margin-bottom: 0;
	}
}

// Lead
p.lead {
	font-size: 1.33em;
	line-height: 1.5em;
	color: @brand-primary;
	font-weight: 300;
	margin-bottom: @line-height-computed;
}

// Evidence
p.evidence, div.evidence {
	color: #fff;
	background-color: @brand-primary;
	padding: @line-height-computed*2;
	margin-bottom: @line-height-computed;
	@media (max-width: @screen-sm-max) {
		padding: 30px;
	}
	@media (max-width: @screen-xs-max) {
		padding: 15px;
	}

	a {
		color: #fff;
	}

	a.btn {
		background-color: #fff;
		color: @brand-primary;

		&:hover {
			background-color: darken(#fff, 10%);
		}
	}
}

// Img
img {
	max-width: 100%;
	height: auto;

	&[style*="left"] {
		margin-right: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	&[style*="right"] {
		margin-left: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

// Table
table {
	border: 1px solid #d8d8d8;
	margin-bottom: @line-height-computed;
	width: 100%;

	th {
		background-color: @brand-primary;
		color: #fff;
		text-transform: uppercase;
		font-weight: 400;
	}

	td {
		background-color: #f0f0f0;
	}

	tr.even td {
		background-color: darken(#f0f0f0, 5%);
	}

	td, th {
		padding: @line-height-computed/2 @line-height-computed;
	}
}

// Iframe responsive
.iframe-embed {
	display: block;
	position: relative;
	margin-bottom: @line-height-computed;
	padding: 0;
	padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

	iframe {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}

// Boutons
.btn {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 25px 40px;
    border: 0;
    border-radius: 4px;
    font-family: @ff-open;
    font-size: 1.13rem;
    font-weight: 700;
    line-height: 1.11em;
    text-align: center;
    outline: 0 !important;
    white-space: normal;
    .transition(all .2s);
    &.btn-primary, &.btn-default, &.btn1 {
		background-color: @brand-blue;
		color: #fff;
		&:hover {
			background-color: darken(@brand-blue, 15%);
		}
    }
}


// Items
.item {
	&--news {
		margin: 15px 0;
		.content {
			padding: 40px;
			min-height: 260px;
			background-color: #fff;
			@media(max-width: @screen-md-max) {
				padding: 30px;
			}
			@media(max-width: @screen-sm-max) {
				padding: 15px;
			}
			h3 {
				margin: 0 0 15px;
				color: #262626;
				font-size: 1.13rem;
				font-weight: 700;
			}
			p {
				margin: 0 0 15px;
				color: #898989;
				font-size: 0.88rem;
				line-height: 1.71em;
			}
			a {
				font-size: 0.88rem;
				font-weight: 700;
				line-height: 1.71em;
				text-decoration: underline;
				text-transform: uppercase;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	&--brand {
		position: relative;
		padding: 15px;
		height: 170px;
		width: 170px;
		background-color: #fff;
		border: 2px solid #e6e6e6;
		.wrapper {
			position: relative;
			height: 100%;
			> * {
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%,-50%);
				-moz-transform: translate(-50%,-50%);
				-ms-transform: translate(-50%,-50%);
				-o-transform: translate(-50%,-50%);
				transform: translate(-50%,-50%);
			}
		}
	}
	&--solution {
		display: block;
		margin: 15px 0;
		background-color: #fff;
		text-decoration: none !important;
		.transition(all .2s);
		.content {
			padding: 14px 30px;
			min-height: 72px;
			@media (max-width : @screen-xs-max) {
				min-height: 0;
			}
			h3 {
				margin: 0;
				color: #262626;
				font-size: 1.13rem;
				font-weight: 700;
			}
		}
		&:hover {
			box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.2);
		}
	}
	&--service {
		.clearfix;
		margin: 60px 0;
		.content, .img-wrapper {
			position: relative;
			float: left;
			width: 50%;
		}
		.content {
			padding: 40px 60px 60px;
			min-height: 480px;
			background-color: #fff;
			@media (max-width : @screen-sm-max) {
				width: 66.66666667%;
			}
			@media (max-width : @screen-xs-max) {
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
			&:before, &:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				background-color: #fff;
			}
			&:before {
				right: 100%;
				width: 9999px;
			}
			&:after {
				left: 100%;
				width: 50%;
				@media (max-width : @screen-sm-max) {
					width: 25%;
				}
				@media (max-width : @screen-sm-max) {
					width: 9999px;
				}
			}
			.title {
				margin-top: 0;
				margin-bottom: 30px;
			}
		}
		.img-wrapper {
			@media (max-width : @screen-sm-max) {
				width: 33.33333333%;
			}
			@media (max-width : @screen-xs-max) {
				width: 100%;
			}
			img {
				margin-top: 50px;
				@media (max-width : @screen-xs-max) {
					margin-top: -25px;
				}
			}
		}
		&:nth-child(even) {
			.content {
				left: 50%;
				@media (max-width : @screen-sm-max) {
					left: 33.33333333%;
				}
				@media (max-width : @screen-xs-max) {
					left: auto;
				}
				&:before {
					width: 50%;
					@media (max-width : @screen-sm-max) {
						width: 25%;
					}
					@media (max-width : @screen-xs-max) {
						width: 9999px;
					}
				}
				&:after {
					width: 9999px;
				}
			}
			.img-wrapper {
				right: 50%;
				@media (max-width : @screen-sm-max) {
					right: 66.66666667%;
				}
				@media (max-width : @screen-xs-max) {
					right: auto;
				}
			}
		}
	}
}

#description-block, #services {
	overflow-x: hidden;
}

#articles {
	padding-top: 85px;
	padding-bottom: 65px;
	&.sm-pad {
		padding-top: 30px;
		padding-bottom: 40px;
	}
	h2 {
		margin-top: 0;
		margin-bottom: 30px;
	}
}

#references {
	padding-top: 40px;
	padding-bottom: 200px;
	@media (max-width : @screen-sm-max) {
		padding-bottom: 120px;
	}
	@media (max-width : @screen-xs-max) {
		padding-bottom: 60px;
	}
	h2 {
		margin-top:0;
		margin-bottom: 20px;
	}
}

#services {
	padding-top: 60px;
	padding-bottom: 140px;
	@media (max-width : @screen-sm-max) {
		padding-bottom: 120px;
	}
	@media (max-width : @screen-xs-max) {
		padding-bottom: 60px;
	}
}

#node-details {
	// background-color: #fff;
	.content-body {
		.clearfix;
	}
	.img {
		img {
			float: left;
			margin: 0 30px 30px 0;
			@media (max-width : @screen-sm-max) {
				float: none;
				margin: 0 0 30px;
			}
		}
	}
}

.content-overflow {
	padding-top: 125px;
	padding-bottom: 85px;
	&.sm-pad {
		padding-bottom: 30px;
	}
	.wrapper {
		position: relative;
		padding-top: 1px;
		background-color: #fff;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 100%;
			width: 9999px;
			background-color: #fff;
		}
		@media (max-width: @screen-sm-max) {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 100%;
				width: 9999px;
				background-color: #fff;
			}
		}
		.text {
			margin-top: -70px;
			padding-bottom: 30px;
			.title {
				margin-top: 0;
				margin-bottom: 50px;
			}
		}
		img {
			margin: -1px 0 0;
			width: 100%;
		}
	}
}

body.page-user, body.page-search404, body.page-search, body.page-node-done {
	.main-wrapper {
		.content-body;
	}
}


.modal {
	padding: 0 !important;
	.close {
		position: absolute;
		top: -32px;
		right: -32px;
		color: #fff;
		font-size: 32px;
		line-height: 1em;
		text-shadow: none;
		opacity: 1;
		.transition(all .2s);
		@media (max-width : @screen-md-max) {
			right: -16px;
		}
		@media (max-width: @screen-sm-max) {
			right: 0;
		}
		&:hover {
			color: @brand-primary;
		}
	}
}
.modal-dialog {
	margin: 60px auto;
	padding: 0 15px;
	width: 960px;
	max-width: 100%;
}
.modal-content {
	border: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.modal-body {
	&.no-gap {
    	padding: 0;
    }
}


.view-actualites {
	padding-top: 75px;
	padding-bottom: 60px;
}

.carousel {
	img {
		width: 100%;
	}
}

.content-paragraphs {
	margin-top: 60px;
	margin-bottom: 60px;
}

.content-evidence {
	position: relative;
	padding-top: 60px;
	padding-bottom: 120px;
	background-color: #fff;
	@media (max-width: @screen-xs-max) {
		padding-bottom: 0;
	}
	.imgWrapper {
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 66.66666667%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		@media (max-width: @screen-xs-max) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			margin-top: 60px;
			padding-bottom: 56.25%;
			width: 100%;
		}
	}
	.content {
		position: relative;
		z-index: 5;
	}
	h2:first-child {
		margin-top: 0;
	}
}